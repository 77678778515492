import {
  InputChangeEventDetail,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToggle,
  IonToolbar,
  useIonAlert,
  useIonToast,
  IonPopover,
  IonTextarea,
  IonModal
} from "@ionic/react";
import "./CycleCountEntry.css";
import { CycleCountStatus } from "../util/Constants";
import React from 'react';
import OnlineStatus from "./OnlineStatus";
import { useContext, useEffect, useState, useRef } from "react";
import { close } from 'ionicons/icons';
import { TranslationsContext } from "../util/Translations";
import { getDataForCycleItem } from "../api/CycleCountGroups";
import {
  CountEntryCard,
  InventoryField,
  MaterialIssueModal,
  formatData,
  getAllFields,
} from "../models/materialissue/MaterialIssue";
import {
  barcodeOutline,
  barcodeSharp,
  chevronBackOutline,
  chevronDownOutline,
  chevronUpOutline,
  filterCircleOutline,
  filterCircleSharp,
  arrowUpCircle,
  chatboxEllipsesOutline,
} from "ionicons/icons";
import {
  CountEntryRequestData,
  countEntryOfItems,
} from "../api/CycleCountBatchs";
import { updateErrorMessageCycleCount } from "../api/Materials";
import { getOptionByIdWithCORP } from "../api/TabwareOptions";
import { IonInputCustomEvent } from "@ionic/core";
import { logCustomMessage } from '../util/logger';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { getInventoryControl } from "../api/PlantSetups";
import { ColumnsWithGroupData, getColumnsWithGroup } from "../api/Windows";
import { GetColumnsWithGroupResponse } from "../models/windows/GetColumnsWithGroup";
import { useStoresManager } from "../hooks/useStoresManager";
import { useHistory, useParams } from "react-router";
import { decodeParam } from "../util/ApiHelper";
import {
  performBarCodeShimProcess,
  findObjectByElementType,
} from "../util/BarCodeHelper";
import { useBarcodeManager } from "../hooks/useBarcodeManager";
interface Comments {
  [key: number]: string;
}
const CycleCountEntry: React.FC<MaterialIssueModal> = () => {
  const [metadata, setMetadata] = useState<GetColumnsWithGroupResponse>();
  const [itemField, setItemField] = useState<GetColumnsWithGroupResponse>();
  const { batchId } = useParams<{ batchId: string }>();
  const displayBatchId = decodeParam(batchId);
  const { isCommentsRequired, checkRequireComments } = useStoresManager();
  const [presentAlert] = useIonAlert();
  const { translations } = useContext(TranslationsContext);
  const [showLoading, setShowLoading] = useState(false);
  const [countEntryDetails, setCountEntryDetails] = useState<CountEntryCard[]>(
    []
  );
  const [countData, setCountData] = useState<CountEntryCard[]>([]);
  const [countEntryDisplay, setCountEntryDisplay] = useState<CountEntryCard>();
  const [scanOption, setScanOption] = useState<string>("Item");
  const { scanText, setScanText, scanClick } = useBarcodeManager();
  const [includeCounted, setIncludeCounted] = useState<boolean>(false);
  const [count, setCount] = useState<number>();
  const [expanded, setExpanded] = useState(false);
  const [comments, setComments] = useState<Comments>({});
  const [resultsFilter, setResultsFilter] = useState("");
  const [multipleSelect, setMultipleSelect] = useState<number[]>([]);
  const [assignToSelect, setAssignToSelect] = useState<number[]>([]);
  const EAMDefaultDate = "0001-01-01T00:00:00";
  const [itemVal, setItemVal] = useState<string>("");
  const [isCardFocused, setIsCardFocused] = useState(false);
  const blurTimeoutId = useRef(null);
  const [presentToast] = useIonToast();
  const [isSaveSuccessful, setIsSaveSuccessful] = useState<boolean[]>(new Array(countEntryDetails.length).fill(false));
  const [isSaveNotSuccessful, setIsNotSaveSuccessful] = useState<boolean[]>(new Array(countEntryDetails.length).fill(false));
  const contentRef = useRef<HTMLIonContentElement>(null);
  const [showGroupList, setShowGroupList] = useState(true);
  const [showAssigneeList, setShowAssigneeList] = useState(true);
  const [showCommentPopup, setShowCommentPopup] = useState(false);
  const [commentIndex, setCommentIndex] = useState<number | null>(null);
  const [inControlResponse, setInControlResponse] = useState(false);
  const [isCommentPopoverOpen, setIsCommentPopoverOpen] = useState(false);
  const [openedPopoverIndex, setOpenedPopoverIndex] = useState(-1);
  const [isToggleOn, setToggleOn] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const [failedIndices, setFailedIndices] = useState(new Set<number>());
  const [inputEmpty, setInputEmpty] = useState(true);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [inputFieldindex, setInputFieldIndex] = useState<number>(-1);
  const inputRefs = useRef<Array<HTMLIonInputElement | null>>([]);
  const [popoverJustClosed, setPopoverJustClosed] = useState(false);
  let popoverTimeoutId: NodeJS.Timeout | null = null;
  const [inputCommentValue, setInputCommentValue] = useState("");

  //TODO: create component for scanner with text and button together
  const getCountEntry = async () => {
    setShowLoading(true);
    setMultipleSelect([]);
    setAssignToSelect([]);
    setCountEntryDetails([]);
    setCountData([]);
    setScanText("");
    const data = {
      batchId: Number(displayBatchId),
    };
    getDataForCycleItem(data).then((response) => {
      logCustomMessage(
        "Data for cycle Item",
        SeverityLevel.Information,
        'CycleCountGroups/GetDataForCycleItem',
        data,
        response
      );
      const sortedData = [...response.data.data];
      if (response.data.data.length > 0 && globalThis.sortedColumn in response.data.data[0]) {
        sortedData.sort((a, b) => {
          if (a[globalThis.sortedColumn] < b[globalThis.sortedColumn]) {
            return -1;
          }
          if (a[globalThis.sortedColumn] > b[globalThis.sortedColumn]) {
            return 1;
          }
          return 0;
        });
      }
      setCountEntryDetails(sortedData);
      setCountData(response.data.data);
      setCountEntryDisplay(response.data.data);

      const uniqueGroupNumbers = new Set(response.data.data.map((item: any) => item.GroupNumber));
      setShowGroupList(uniqueGroupNumbers.size > 1);
      const uniqueAssignees = new Set(response.data.data.map((item: any) => item.AssignedTo));
      setShowAssigneeList(uniqueAssignees.size > 1);

      setShowLoading(false);
    });

    //TODO: create a helper to get options and use in all places calling this api
    const response = await getOptionByIdWithCORP(
      "CycleCountingScannerConfiguration"
    );
    if (response.status === 200 && response?.data) {
      //otherwise leave "Item" as default
      setScanOption(
        response.data.PlantValue
          ? response.data.PlantValue
          : response.data.DefaultValue
      );
    }
  };

  const getIssueDetailsColumns = async () => {
    const data: ColumnsWithGroupData = {
      WindowName: "mob_inventory_cyclecount",
    };
    const response = await getColumnsWithGroup(data);
    setMetadata(response.data);
  };

  const getScanItemField = async () => {
    const data: ColumnsWithGroupData = {
      WindowName: "mob_inventory_cyclecount_scan",
    };
    const response = await getColumnsWithGroup(data);
    setItemField(response.data);
  };

  const setCountValue = async (countValue: any, index: number, isSuccess: boolean) => {
    const newCountData = [...countData];
    const newCountEntryDetails = [...countEntryDetails];

    if (isSuccess) {
      const entryIndex = newCountEntryDetails.findIndex((d) => {
        return d.CountId === index;
      });

      if (entryIndex > -1) {
        newCountEntryDetails[entryIndex].CountResult = countValue;
      }
    }
    else {
      const i = newCountData.findIndex((d) => {
        return d.CountId === newCountEntryDetails[index].CountId;
      });
      if (i > -1) {
        newCountData[i].CountResult = countValue;
      }
    }
    setCount(countValue);
  };

  const handleIncludeCounted = async (selected: boolean) => {
    setIncludeCounted(selected);
  };

  const handleExpanded = async (expanded: boolean, index: number) => {
    countEntryDetails[index].expanded = expanded;
    setExpanded(countEntryDetails[index].expanded);
  };

  const handleComment = async (comments: string, index: number) => {
    const commentValue = comments.trim() === "" ? null : comments;
    countEntryDetails[index].Comments = commentValue;
    setComments((prevComments) => ({
      ...prevComments,
      [index]: comments,
    }));
  };


  const saveCountEntry = async (cardIndex: number) => {
    if (showCommentPopup) {
      return;
    }
    setShowLoading(true);
    let updatedCountData = [...countEntryDetails];
    let newCountEntryDetails = [...updatedCountData];
    let failMessages: string[] = [];
    let successMessages: string[] = [];

    const inControlResponse = await getInventoryControl();
    for (let countEntry of countData) {
      let isCommentsRequired = false;
      if (countEntry.CountResult >= 0 && String(countEntry.CountResult) !== "") {
        setShowLoading(true);
        const Cardindex = updatedCountData.findIndex(item => item === countEntry);
        if (cardIndex !== Cardindex && commentIndex !== Cardindex) {
          setShowLoading(false);
          continue;
        }
        if (countEntry.Comments === null && (inControlResponse.data.AdjustQtyCommentFlag || countEntry.ReqCommentOnDiscrepFlag) && countEntry.CountResult !== countEntry.SystemQty) {
          const errorMessage = `Comment is required for Item: ${countEntry.Item}`;
          failMessages.push(errorMessage);
          isCommentsRequired = true;
          let index = updatedCountData.findIndex(item => item === countEntry);
          if (index > -1) {
            setFailedIndices(prevIndices => new Set(prevIndices.add(index)));
          }
          setErrorMessages(prevMessages => {
            const newMessages = [...prevMessages];
            newMessages[index] = errorMessage;
            return newMessages;
          });
          if ('ErrorMessage' in countEntryDetails[index]) {
            const CommentData: CountEntryRequestData = {
              ...countEntry,
              ErrorMessage: errorMessage
            };
            await updateErrorMessageCycleCount(CommentData);
          }
        }
        if (!isCommentsRequired) {
          const data: CountEntryRequestData = {
            ...countEntry,
            CountedQty: String(countEntry.CountResult),
            Comments: Object.values(comments)[0],
          };
          const dataParams = {
            RequestObject: [data],
            Confirmation: {},
          };

          const response = await countEntryOfItems(dataParams);
          logCustomMessage(
            "Count entry of Items",
            SeverityLevel.Information,
            'CycleCountBatchs/CountEntryofItems',
            dataParams,
            response
          );

          if (response.data.Success === false || response.data.CountEntryResults[0].Success === false) {
            const errorMessage = response.data.MessageList.Text || response.data.CountEntryResults[0].MessageList[0]?.Text;
            const formattedErrorMessage = `${errorMessage} for item: ${countEntry.Item}`;
            failMessages.push(formattedErrorMessage);
            let index = updatedCountData.findIndex(item => item === countEntry);
            if (index > -1) {
              setFailedIndices(prevIndices => new Set(prevIndices.add(index)));
            }
            setErrorMessages(prevMessages => {
              const newMessages = [...prevMessages];
              newMessages[index] = formattedErrorMessage;
              return newMessages;
            });
            if ('ErrorMessage' in countEntryDetails[index]) {
              const ErrorData: CountEntryRequestData = {
                ...countEntry,
                ErrorMessage: formattedErrorMessage
              };
              await updateErrorMessageCycleCount(ErrorData);
            }
          } else {
            let successMsg = `Item ${countEntry.Item} successfully counted.`;
            successMessages.push(successMsg);
            let index = updatedCountData.findIndex(item => item === countEntry);
            if (index > -1) {
              const newIsSaveSuccessful = [...isSaveSuccessful];
              newCountEntryDetails[index].DatetimeCounted = new Date().toISOString();
              newIsSaveSuccessful[index] = true;
              setIsSaveSuccessful(newIsSaveSuccessful);
              setFailedIndices(prevIndices => {
                const newIndices = new Set(prevIndices);
                newIndices.delete(index);
                return newIndices;
              });
              setErrorMessages(prevMessages => {
                const newMessages = [...prevMessages];
                newMessages[index] = '';
                return newMessages;
              });
              setCountEntryDetails(prevCountData => {
                const newCountData = [...prevCountData];
                newCountData[index].Variance = Math.abs(countEntry.CountResult - countEntry.SystemQty);
                return newCountData;
              });
              if ('ErrorMessage' in countEntryDetails[index]) {
                countEntryDetails[index].ErrorMessage = null;
                data.ErrorMessage = null;
                await updateErrorMessageCycleCount(data);
              }
            }
            countEntry.SystemQty = countEntry.CountResult;
            countEntry.Comments = null;
            await setCountValue("", countEntry.CountId, true);
            checkIncludeCounted(countEntry);
            setScanText(''); // Reset the scanText state
            setResultsFilter(''); // Reset the resultsFilter state
          }
        }
      }

    }

    if (failMessages.length > 0) {
      for (const message of failMessages) {
        presentToast({
          message: message,
          duration: 3000,
          position: "top",
          color: "danger"
        });
      }
    }
    if (failMessages.length === 0 && successMessages.length > 0) {
      if (!includeCounted) {
        presentToast({
          message: successMessages[0],
          duration: 3000,
          position: "top",
          color: "success"
        });
      }
    }
    setComments({});
    if (!includeCounted) {
      setCountEntryDetails(newCountEntryDetails);
    }
    setShowLoading(false);
  };

  const handleBackButtonClick = () => {
    // Check if there are unsaved transactions with errors
    // Replace `hasUnsavedTransactionsWithErrors` with your actual condition
    if (Array.from(failedIndices).length > 0) {
      // Show an alert
      presentAlert({
        header: 'Unsaved Transactions',
        message: 'There are unsaved transactions with errors. Are you sure you want to leave?',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
          },
          {
            text: 'Leave',
            handler: () => {
              // Navigate back to the home page
              history.goBack();
            },
          },
        ],
      });
    } else {
      // If there are no unsaved transactions with errors, navigate back to the home page
      history.goBack();
    }
  };

  async function onScanTextEnter(
    ev: IonInputCustomEvent<InputChangeEventDetail>
  ): Promise<void> {
    let res = String(ev.target.value);
    const scanMatchList = filterItem(res);
    setItemVal('');
    setScanText('');
    setTimeout(() => {
      setItemVal(res);
      setScanText(res);
    }, 0);
    if (itemVal === res) return;
    if (scanMatchList.length === 0) {
      const barCodeFieldData = findObjectByElementType(itemField?.fields);
      const barcodeResult = await performBarCodeShimProcess(
        barCodeFieldData,
        res
      );
      if (barcodeResult) {
        if (barcodeResult.NoMatch) {
          setItemVal(res);
          setScanText(res);
          setCountEntryDetails(countData);
          return;
        }
        let capturingGroups = barcodeResult.CapturingGroupValues;
        if (capturingGroups?.Item || barcodeResult.FirstCapturingGroup) {
          res = capturingGroups?.Item || barcodeResult.FirstCapturingGroup;
          setItemVal(res);
          setCountEntryDetails(filterItem(res));
        } else {
          presentAlert("Scanned item not found.");
          setCountEntryDetails(countData);
        }
      }
    } else {
      setCountEntryDetails(scanMatchList);
    }
    setResultsFilter("");
  }

  function filterItem(input: string) {
    return input
      ? countData.filter((d) => {
        return scanOption === "Item"
          ? d.Item.toLocaleLowerCase() === input.toLocaleLowerCase()
          : d.Item.toLocaleLowerCase() + d.Bin.toLocaleLowerCase() ===
          input.toLocaleLowerCase();
      })
      : countData;
  }

  const checkIncludeCounted = (entry: CountEntryCard) => {
    if (
      (!includeCounted && entry.DatetimeCounted !== EAMDefaultDate) ||
      (!multipleSelect.map(Number).includes(entry.GroupNumber) &&
        multipleSelect.length !== 0) || (!assignToSelect.map(String).includes(entry.AssignedTo) && assignToSelect.length != 0) ||
      entry.Item.toLocaleLowerCase().indexOf(
        resultsFilter.toLocaleLowerCase()
      ) === -1
    ) {
      return true;
    } else {
      return false;
    }
  };

  const expandedSection = (data: any, field: InventoryField, index: number) => {
    if (field.ElementType === "textArea") {
      return null;
    }
    if (
      field.FieldOrder !== "01" &&
      field.FieldOrder !== "02" &&
      field.FieldOrder !== "03" &&
      field.FieldOrder !== "04"
    ) {
      return (
        <p key={field.IdText}>
          {field.TranslatedIdText}: {formatData(data, field)}
        </p>
      );
    } else {
      return null;
    }
  };

  const openCommentPopover = (index: number) => {
    setCommentIndex(index);
    setShowCommentPopup(true);
    setIsCommentPopoverOpen(true); // Set the new state variable to true
    setOpenedPopoverIndex(index); // Set the index of the opened comment popover
  };

  useEffect(() => {
    checkRequireComments();
    getIssueDetailsColumns();
    getCountEntry();
    getScanItemField();
  }, [batchId]);

  useEffect(() => {
    getInventoryControlData();
  }, [metadata]);

  const getInventoryControlData = async () => {
    const response = await getInventoryControl();
    setInControlResponse(response.data.AdjustQtyCommentFlag);
  }

  const scrollToTop = () => {
    contentRef.current?.scrollToPoint(0, 0, 1000); // x, y, duration in ms
  };
  const onFilterChange = async (event: any) => {
    setResultsFilter(event.target.value);
  };

  useEffect(() => {
    if (popoverJustClosed) {
      if (inputFieldindex !== null) {
        inputRefs.current[inputFieldindex]?.getInputElement().then(input => input.focus());
      }
      setPopoverJustClosed(false);
    }
  }, [popoverJustClosed, inputFieldindex]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={handleBackButtonClick}>
              <IonIcon slot="icon-only" icon={chevronBackOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>
            {translations["lbl_count_entry"] || "Count Entry"}
          </IonTitle>
        </IonToolbar>
        <OnlineStatus />
      </IonHeader>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
      />
      <IonContent ref={contentRef}>
        <IonItem>
          <IonButton
            fill="clear"
            size="small"
            onClick={() => {
              handleIncludeCounted(!includeCounted);
            }}
          >
            <IonLabel>
              {translations["lbl_include_counted"] || "Include Counted"}
            </IonLabel>
            <IonToggle
              style={{ transform: "scale(0.80)" }}
              checked={includeCounted}
              onIonChange={e => setIncludeCounted(e.detail.checked)}
            />
          </IonButton>
          {(showGroupList || showAssigneeList) && (
            <IonButton slot="end" size="small" onClick={() => setShowModal(true)}>Show Filters</IonButton>
          )}

          <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
            <IonHeader>
              <IonToolbar>
                <IonTitle>Filters</IonTitle>
                <IonButtons slot="end">
                  <IonButton onClick={() => setShowModal(false)}>Close</IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent>

              <IonList>
                {showGroupList && (
                  <IonItem>
                    <IonLabel position="floating">
                      {translations["lbl_mob_filterbygroup"] || "Filter By Group"}:
                    </IonLabel>
                    <IonSelect
                      placeholder="Select groups"
                      value={multipleSelect}
                      multiple={true}
                      onIonChange={(ev) => setMultipleSelect(ev.detail.value)}
                    >
                      {Array.from(new Set(countData.map((a) => a.GroupNumber)))?.map(
                        (g) => (
                          <IonSelectOption key={g}>{g}</IonSelectOption>
                        )
                      )}
                    </IonSelect>
                  </IonItem>
                )}
                {showAssigneeList && (
                  <IonItem>
                    <IonLabel position="floating">
                      {translations["lbl_mob_filterbyassignedto"] || "Filter By Assigned To"}:
                    </IonLabel>
                    <IonSelect
                      placeholder="Select assignees"
                      value={assignToSelect}
                      multiple={true}
                      onIonChange={(ev) => setAssignToSelect(ev.detail.value)}
                    >
                      {Array.from(new Set(countData.map((a) => a.AssignedTo)))
                        .filter(assignee => assignee != null)
                        .map(assignee => (
                          <IonSelectOption key={assignee}>{assignee}</IonSelectOption>
                        ))}
                    </IonSelect>
                  </IonItem>
                )}
              </IonList>
            </IonContent>
          </IonModal>
        </IonItem>
        <IonItem>
          {isToggleOn ? (
            <>
              <IonInput
                type="text"
                clearInput={true}
                debounce={1500}
                class="ion-text-begin"
                placeholder={itemField?.fields["in_item_location.item"]?.TranslatedIdText}
                onIonChange={(ev) => onScanTextEnter(ev)}
                value={scanText}
              ></IonInput>
              <IonIcon
                slot="end"
                ios={barcodeOutline}
                md={barcodeSharp}
                onClick={() => scanClick()}
              ></IonIcon>
            </>
          ) : (
            <>
              <IonInput
                type="text"
                clearInput={true}
                debounce={500}
                class="ion-text-begin"
                placeholder={translations["lbl_filter"] || "Filter"}
                onIonChange={(ev) => onFilterChange(ev)}
                value={resultsFilter}
              ></IonInput>
              <IonIcon
                slot="end"
                ios={filterCircleOutline}
                md={filterCircleSharp}
              ></IonIcon>
            </>
          )}
          <IonToggle
            slot="end"
            onIonChange={(e) => {
              setToggleOn(e.detail.checked);
              setScanText(''); // Reset the scanText state
              setResultsFilter(''); // Reset the resultsFilter state
            }}
          />
        </IonItem>
        {!!metadata &&
          !!countEntryDisplay &&
          countEntryDetails.map((entry, index) => {
            return (
              <IonCard
                key={index}
                className={
                  (failedIndices.has(index) || countEntryDetails[index].ErrorMessage)
                    ? 'card-highlight-failure'
                    : (isSaveSuccessful[index] === true || (countEntryDetails[index].DatetimeCounted !== EAMDefaultDate && (countEntryDetails[index].ErrorMessage === null || countEntryDetails[index].ErrorMessage === ""))) && includeCounted
                      ? 'card-highlight-success'
                      : ''
                }
                hidden={checkIncludeCounted(entry)}
              // id={`card-${index}`}
              >
                <IonCardContent>
                  <IonGrid class="ion-no-padding no-padding-top">
                    <IonRow>
                      <IonCol>
                        {(errorMessages[index] || countEntryDetails[index].ErrorMessage) && <p className="error-message">{(errorMessages[index] || countEntryDetails[index].ErrorMessage)}</p>}
                        <IonCardTitle>
                          {entry.Item}: <span className="description">{entry.Description}</span>
                        </IonCardTitle>
                      </IonCol>
                      <IonCol size="auto" class="ion-no-padding">
                        <IonButton
                          size="small"
                          fill="clear"
                          disabled={countEntryDetails[index].Status === CycleCountStatus.Closed || countEntryDetails[index].Status === CycleCountStatus.Created || countEntryDetails[index].Status === CycleCountStatus.Deleted}
                          onMouseDown={(e) => {
                            e.preventDefault(); // This will prevent the onBlur event from firing
                            setCommentIndex(index);
                            setShowCommentPopup(true);
                          }}
                        >
                          <IonIcon
                            slot="icon-only"
                            icon={chatboxEllipsesOutline}
                            color={(openedPopoverIndex === index && !comments[index] && count !== countEntryDetails[index].SystemQty && !inputEmpty) ? "danger" : "primary"}
                          />
                        </IonButton>
                        <IonButton
                          size="small"
                          fill="clear"
                          onMouseDown={(e) => {
                            e.preventDefault(); // This will prevent the onBlur event from firing
                            handleExpanded(!expanded, index);
                          }}
                        >
                          <IonIcon
                            size="small"
                            slot="icon-only"
                            icon={
                              entry?.expanded
                                ? chevronUpOutline
                                : chevronDownOutline
                            }
                          ></IonIcon>
                        </IonButton>
                      </IonCol>
                    </IonRow>
                    <IonRow class="ion-padding-top">
                      <IonCol>
                        {getAllFields(metadata).map((field) => {
                          let data =
                            entry[field.PropertyName as keyof CountEntryCard];
                          if (field.PropertyName === "DatetimeCounted" && data === EAMDefaultDate) {
                            data = "";
                          }
                          return (
                            <>
                              {
                                field.FieldOrder === "03" ||
                                  (field.FieldOrder === "04" && !entry.BlindCountedFlag) ? (
                                  <p key={field.IdText}>
                                    {field.TranslatedIdText}:{" "}
                                    {field.PropertyName === "QtyOnHand"
                                      ? entry.SystemQty
                                      : formatData(data, field)}
                                  </p>
                                ) : (
                                  <></>
                                )}
                              {entry.expanded &&
                                expandedSection(data, field, index)}
                            </>
                          );
                        })}
                      </IonCol>
                      <IonCol size="auto">
                        <IonItem
                          fill="outline"
                          mode="md"
                          style={{ maxWidth: 84 }}
                          lines="full"
                        >
                          {entry.SerialTrackingFlag ? (
                            <IonInput
                              value={entry.SystemQty}
                              disabled
                            ></IonInput>
                          ) : (
                            <IonInput ref={(el) => inputRefs.current[index] = el}
                              inputMode="decimal"
                              type="number"
                              // class="small-input"
                              value={countEntryDetails[index].CountResult}
                              disabled={entry.Status === CycleCountStatus.Closed || entry.Status === CycleCountStatus.Created || countEntryDetails[index].Status === CycleCountStatus.Deleted}
                              onIonChange={async (ev) => {
                                logCustomMessage(
                                  "User input in IonInput",
                                  SeverityLevel.Information,
                                  'CycleCountBatchs/UserInput',
                                  { inputValue: ev.target.value }, // Pass the input value as a parameter
                                  null // No response in this case
                                );
                                if (!ev.target.value && ev.target.value !== 0) {
                                  setCountValue("", index, false);
                                  setInputEmpty(true);
                                  return;
                                }
                                if (Number.parseInt(ev.target.value.toString()) < 0) {
                                  ev.target.value = "";
                                  return;
                                }
                                setCountValue(Number(ev.target.value), index, false);
                                setInputEmpty(false);
                                setInputFieldIndex(index);

                                if ((inControlResponse || entry.ReqCommentOnDiscrepFlag) && Number(ev.target.value) !== countEntryDetails[index].SystemQty) {
                                  // If both conditions are met, open the comment popover
                                  if (popoverTimeoutId) {
                                    clearTimeout(popoverTimeoutId);
                                  }

                                  // Set a new timeout
                                  popoverTimeoutId = setTimeout(() => {
                                    openCommentPopover(index);
                                  }, 1000); // Delay of 1 second
                                }

                              }}
                              onFocus={() => {
                                setIsCardFocused(true);
                                if (blurTimeoutId.current !== null) {
                                  clearTimeout(blurTimeoutId.current);
                                  blurTimeoutId.current = null;
                                }
                              }}
                              onBlur={() => {
                                const inputValue = countEntryDetails[index].CountResult;

                                // Only call saveCountEntry if the input field has a value, the card was focused,
                                // the newly focused element is not in the same card, and the newly focused element is not in the popover
                                if (isCardFocused && inputValue !== null && inputValue !== undefined && inputValue.toString() !== "" && !isCommentPopoverOpen) {
                                  saveCountEntry(inputFieldindex);
                                  setIsCardFocused(false);
                                }
                              }}
                            ></IonInput>
                          )}
                        </IonItem>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonCardContent>
                <IonPopover
                  isOpen={showCommentPopup && commentIndex === index}
                  onDidDismiss={(e) => {
                    setShowCommentPopup(false);
                    setIsCommentPopoverOpen(false);
                    setPopoverJustClosed(true);
                    if (e.detail.role !== 'submit') {
                      setInputCommentValue('');
                    }
                  }}
                  onDidPresent={() => {
                    setInputCommentValue(comments[index] || ''); // Update inputCommentValue with the saved comment when the popover is opened
                  }}
                  class='my-custom-class'
                >
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <IonLabel>{translations["lbl_category_comments"] || "Comments"}</IonLabel>
                    <IonButton
                      fill="clear"
                      onClick={() => {
                        setShowCommentPopup(false)
                        inputRefs.current[inputFieldindex]?.getInputElement().then(input => input.focus());
                      }}
                    >
                      <IonIcon icon={close} />
                    </IonButton>
                  </div>
                  <IonTextarea
                    class="my-custom-textarea"
                    placeholder={translations["lbl_enter comments"] || "Enter Comments"}
                    value={inputCommentValue || ""}
                    onIonChange={(ev) => {
                      setInputCommentValue(ev.target.value || "");
                    }}
                  />
                  <IonButton
                    type="submit"
                    expand="block"
                    onClick={() => {
                      handleComment(inputCommentValue, index);
                      setInputCommentValue(inputCommentValue);
                      setShowCommentPopup(false);
                      inputRefs.current[inputFieldindex]?.getInputElement().then(input => input.focus());
                    }}
                  >
                    Save
                  </IonButton>
                </IonPopover>
              </IonCard>
            );
          })}
      </IonContent>
      <IonIcon
        icon={arrowUpCircle}
        onClick={scrollToTop}
        style={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          fontSize: '30px',
          zIndex: 1000,
        }}
      />
    </IonPage>
  );
};

export default CycleCountEntry;
