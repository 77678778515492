import {
  IonAccordion,
  IonAccordionGroup,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCheckbox,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonNote,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonTitle,
  IonToolbar,
  RefresherEventDetail,
  useIonAlert,
} from "@ionic/react";
import { chevronBackOutline, chevronBackSharp } from "ionicons/icons";
import { useContext, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useHistory, useParams } from "react-router";
import OnlineStatus from "../components/OnlineStatus";
import {
  InspectionDef,
  PointDefType,
  PointValue,
  saveInspection,
  PointEqDescription
} from "../models/inspections/Inspections";
import { decodeParam } from "../util/ApiHelper";
import "./Inspection.css";
import {
  TranslationMessagesContext,
  TranslationsContext,
} from "../util/Translations";
import { ApiResponse } from "../models/api/ApiResponse";
import { getInspectionDefFullHierarchy } from "../api/InspectionDefinitions";
import {
  getInspectionResults,
  getInspectionResultsFullHierarchy,
} from "../api/InspectionResults";
import ApiError from "../components/ApiError";
import {
  getInspectionFromLocal,
  removeInspectionFromLocal,
  saveInspectionToLocal,
} from "../util/InspectionsHelper";
import { getWorkOrder } from "../api/WorkOrders";
import { getOptionByIdWithCORP } from "../api/TabwareOptions";
import { getEquipment } from "../api/EquipmentMasters";

const Inspection: React.FC = () => {
  interface TestEquipment {
    Equipment: string,
    ManufacturerSerialId: string,
    LastCalibrated: string,
    CalibrationExpiresDate: string
  }
  interface PointError {
    id: number;
    column: string;
    error: string;
  }
  const { translations } = useContext(TranslationsContext);
  const { translatedMessages } = useContext(TranslationMessagesContext);
  const history = useHistory();
  const { inspectionId } = useParams<{ inspectionId: string }>();
  const { workOrderId } = useParams<{ workOrderId: string }>();

  const [errorState, setErrorState] = useState("");
  const [equipment, setEquipment] = useState("");
  const [testEquipmentList, setTestEquipmentList] = useState<TestEquipment[]>([]);
  const [currentTestEquip, setCurrentTestEquip] = useState("[]");
  const [testEquipRule, setTestEquipRule] = useState("");
  const [inspectionDefinition, setInspectionDefinition] =
    useState<InspectionDef>();
  const [showLoading, setShowLoading] = useState(false);
  const [pointDefs, setPointDefs] = useState<PointDefType[]>([]);
  const [pointVals, setPointVals] = useState<PointValue[]>([]);
  const [checkOverride, setCheckOverride] = useState<boolean>(false);
  const [disableInsp, setDisableInsp] = useState<boolean>(false);
  const [comments, setComments] = useState<string>("");
  const [pointEquipment, setPointEquipment] = useState<PointEqDescription[]>([]);

  const [presentAlert] = useIonAlert();

  const displayWo = decodeParam(workOrderId);
  const requiredColor = "danger";
  let inRefresh = false;

  const handleRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    inRefresh = true;
    if (!disableInsp) {
      await getInspectionDef();
    }
    event.detail.complete();
    inRefresh = false;
  };

  async function processError(status: number, errorData: any) {
    if (errorData) {
      let errMsg: string = "";
      if (status === 400) {
        errMsg = errorData.Message;
      } else if (status === 500) {
        errMsg = errorData[0]?.Text ?? errorData;
      } else {
        errMsg = errorData;
      }
      if (errMsg.includes("relogin")) {
        errMsg =
          translatedMessages["SessionExpired"]?.MessageText ||
          "Your session has expired. Please re-login.";
      }
      setErrorState(errMsg);
    }
  }

  async function getWoEquipment() {
    const respWo = await getWorkOrder({
      woNumber: displayWo
    });
    setEquipment(respWo.data?.Equipment);
  }

  async function getTestEquipRule() {
    const resp = await getOptionByIdWithCORP("ICTypeRequiresTestEQ");
    if (resp.status === 200 && resp.data) {
      setTestEquipRule(resp.data.PlantValue ? resp.data.PlantValue : resp.data.DefaultValue);
    }
  }
  async function getTestEquipmentList() {
    setTestEquipRule("");
    setTestEquipmentList([]);
    //get the rule
    getTestEquipRule();
    //call API and filter where exp date is not set or > today
    const resp = await getEquipment({ filter: "test_equipment_flag eq 1" });
    if (resp.status === 200 && resp.data) {
      setTestEquipmentList(resp.data.filter((r: any) => !r.CalibrationExpiresDate || Date.parse(r.CalibrationExpiresDate) >= Date.now()))
    }
  }
  async function getInspectionDef() {
    await getInspectionDefFullHierarchy({
      //explicitly convert to string -- iOS issue
      inspectionId: String(inspectionId),
    })
      .then((response: ApiResponse) => {
        if (response.isError) {
          processError(response.status, response.data);
        } else if (response.data.InspectionDefinition) {
          const pDefs: PointDefType[] = [];
          const pVals: PointValue[] = [];
          const equipmentVal: Set<string> = new Set();
          const equipmentValues: PointEqDescription[] = [];
          let itemDesc: { [key: string]: string } = {};
          itemDesc = response.data.PointEqpList;
          //parse points metadata
          response.data.InspectionDefinition.Points.forEach(
            (p: PointDefType) => {
              if (p.ValueDataType === "b") {
                p.DefaultValue = "false";
              }
              if (p.RequiredFlag) {
                p.Description = "* " + p.Description;
              }
              let highLow: { high: number; low: number } = {
                high: Number.MAX_VALUE,
                low: Number.MIN_VALUE,
              };
              let desired =
                (
                  p.Meta?.find((m) => {
                    return m.MetaType === "desired";
                  })
                )?.MetaValue ?? 0;
              !!p.Meta &&
                p.Meta.forEach((m) => {
                  if (m.MetaType === "limit") {
                    highLow.high = m.MetaSubtype === "high" ? m.MetaValue : highLow.high;
                    highLow.low = m.MetaSubtype === "low" ? m.MetaValue : highLow.low;
                    p.Limit = true;
                  } else if (m.MetaType === "tolerance") {
                    highLow.high = m.MetaSubtype === "high" ? m.MetaValue : highLow.high;
                    highLow.low = m.MetaSubtype === "low" ? m.MetaValue : highLow.low;
                  } else if (m.MetaType === "tolerance%") {
                    highLow.high = m.MetaSubtype === "high" ? (1 + m.MetaValue * 0.01) * desired : highLow.high;
                    highLow.low = m.MetaSubtype === "low" ? (1 - m.MetaValue * 0.01) * desired : highLow.low;
                  } else if (m.MetaType === "precision") {
                    p.Precision = m.MetaValue;
                  } else if (m.MetaType === "default") {
                    p.DefaultValue = m.MetaSubtype;
                  }
                });
              p.HighValue = highLow.high;
              p.LowValue = highLow.low;

              pDefs.push(p);
              if (p.Equipment != null) {
                const equipDescription = itemDesc[p.Equipment]
                if (!equipmentVal.has(p.Equipment)) {
                  const eq: PointEqDescription = {
                    Equipment: p.Equipment,
                    Description: equipDescription || ""
                  };
                  equipmentValues.push(eq);
                  equipmentVal.add(p.Equipment);
                }
                //add value of p.equipment and equipment description into an object of pointeQDescription and add to set

              } else if (p.Equipment === null) {
                p.Equipment = "No Equipment";
                if (!equipmentVal.has("No Equipment")) {
                  const eq: PointEqDescription = {
                    Equipment: p.Equipment,
                    Description: ""
                  };
                  equipmentValues.push(eq);
                }
                equipmentVal.add(p.Equipment);

              }
              !!p.Columns &&
                p.Columns.forEach((pc) => {
                  pVals.push({
                    PointId: p.PointId,
                    ObjectId: pc.ObjectId,
                    ColumnCode: pc.ColumnCode,
                    Measure: p.DefaultValue,
                    FailedFlag: p.RequiredFlag,
                  });
                });
            }
          );
          setPointEquipment(Array.from(equipmentValues));
          if (response.data.InspectionDefinition.InspectionType === "Calibration") {
            getTestEquipmentList();
          }
          setPointDefs(pDefs);
          setPointVals(pVals);
          setInspectionDefinition(response.data.InspectionDefinition);
        }
      })
      .catch((reason: any) => {
        setPointDefs([]);
        setErrorState(reason);
      });
  }

  const checkExistingResult = async () => {
    const inspectionFromLocal = await getInspectionFromLocal(
      inspectionId,
      displayWo
    );
    if (inspectionFromLocal) {
      const pVals: PointValue[] = [];
      const pDefs: PointDefType[] = [];
      const equipmentVal: Set<string> = new Set();
      const equipmentValues: PointEqDescription[] = [];
      let itemDesc: { [key: string]: string } = {};
      !!inspectionFromLocal.InspectionResultData.Definition &&
        inspectionFromLocal.InspectionResultData.Definition.Points.forEach(
          (p: PointDefType) => {
            pDefs.push(p);
            if (p.Equipment != null) {
              const equipDescription = itemDesc[p.Equipment]
              if (!equipmentVal.has(p.Equipment)) {
                const eq: PointEqDescription = {
                  Equipment: p.Equipment,
                  Description: equipDescription || ""
                };
                equipmentValues.push(eq);
                equipmentVal.add(p.Equipment);
              }
              //add value of p.equipment and equipment description into an object of pointeQDescription and add to set

            } else if (p.Equipment === null) {
              p.Equipment = "No Equipment";
              if (!equipmentVal.has("No Equipment")) {
                const eq: PointEqDescription = {
                  Equipment: p.Equipment,
                  Description: ""
                };
                equipmentValues.push(eq);
              }
              equipmentVal.add(p.Equipment);

            }
          }
        );
      setPointEquipment(Array.from(equipmentValues));

      !!inspectionFromLocal.InspectionResultData.Points &&
        inspectionFromLocal.InspectionResultData.Points.forEach(
          (p: PointValue) => {
            pVals.push({
              PointId: p.PointId,
              ObjectId: p.ObjectId,
              ColumnCode: p.ColumnCode,
              Measure: p.Measure,
              FailedFlag: p.FailedFlag,
            });
          }
        );
      if (inspectionFromLocal.InspectionResultData.Definition.InspectionType === "Calibration") {
        getTestEquipmentList();
        setCurrentTestEquip(JSON.stringify(inspectionFromLocal.InspectionResultData.TestEquipment));
      }
      setComments(inspectionFromLocal.InspectionResultData.Comments);
      setPointDefs(pDefs);
      setPointVals(pVals);
      setInspectionDefinition(inspectionFromLocal.InspectionResultData.Definition);
    } else {
      const response = await getInspectionResults(`wo_base='${workOrderId}'`);
      if (response.isError) {
        processError(response.status, response.data);
      } else if (response.data?.length > 0) {
        const ret = await loadExistingResult(response.data[0].ResultId);
        if (ret === 200) {
          setDisableInsp(true);
          presentAlert({
            header: "Completed",
            message:
              translatedMessages["CompInspExist"]?.MessageText ||
              "Completed inspection already exists for this work order.",
            buttons: [translations["lbl_btn_ok"] || "OK"],
          });
        }
      } else {
        await getInspectionDef();
      }
    }
  };

  async function loadExistingResult(resId: string): Promise<number> {
    const response = await getInspectionResultsFullHierarchy({
      //explicitly convert to string -- iOS issue
      inspectionId: String(inspectionId),
      resultId: String(resId),
    });
    if (response.isError) {
      processError(response.status, response.data);
    } else if (response.data?.InspectionResult) {
      let itemDesc: { [key: string]: string } = {};
      itemDesc = response.data.PointEqpList;
      const pVals: PointValue[] = [];
      const pDefs: PointDefType[] = [];
      const equipmentVal: Set<string> = new Set();
      const equipmentValues: PointEqDescription[] = [];
      !!response.data.InspectionResult.Definition &&
        response.data.InspectionResult.Definition.Points.forEach(
          (p: PointDefType) => {
            pDefs.push(p);
            if (p.Equipment != null) {
              const equipDescription = itemDesc[p.Equipment]
              if (!equipmentVal.has(p.Equipment)) {
                const eq: PointEqDescription = {
                  Equipment: p.Equipment,
                  Description: equipDescription || ""
                };
                equipmentValues.push(eq);
                equipmentVal.add(p.Equipment);
              }
              //add value of p.equipment and equipment description into an object of pointeQDescription and add to set

            } else if (p.Equipment === null) {
              p.Equipment = "No Equipment";
              if (!equipmentVal.has("No Equipment")) {
                const eq: PointEqDescription = {
                  Equipment: p.Equipment,
                  Description: ""
                };
                equipmentValues.push(eq);
              }
              equipmentVal.add(p.Equipment);

            }

          }
        );

      setPointEquipment(Array.from(equipmentValues));

      !!response.data.InspectionResult.Points &&
        response.data.InspectionResult.Points.forEach((p: PointValue) => {
          pVals.push({
            PointId: p.PointId,
            ObjectId: p.ObjectId.replace("|" + resId, ""),
            ColumnCode: p.ColumnCode,
            Measure: p.Measure,
            FailedFlag: p.FailedFlag,
          });
        });
      setComments(response.data.InspectionResult?.Comments);
      setCurrentTestEquip(JSON.stringify(response.data.InspectionResult.TestEquipment));
      setPointDefs(pDefs);
      setPointVals(pVals);
      setInspectionDefinition(response.data.InspectionResult.Definition);
    }
    return response.status;
  }

  useEffect(() => {
    setShowLoading(true);
    getWoEquipment();
    checkExistingResult()
      .then()
      .finally(() => {
        setShowLoading(false);
      });
  }, [inspectionId, workOrderId]);

  const getMetaNotes = (meta: any) => {
    return (
      <IonNote class="inspection-helper-note" slot="helper">
        {getMetaString(meta)}
      </IonNote>
    );
  };

  const getMetaString = (meta: any) => {
    let notes: string = "";
    meta.forEach((metaValue: any) => {
      notes += `${metaValue.MetaSubtype}${metaValue.MetaType === "default" ? "" : ":" + metaValue.MetaValue
        }${metaValue.MetaType === "tolerance%" ? "% " : " "} `;
    });
    return notes;
  };

  const [validationErrors, setValidationErrors] = useState<PointError[]>([]);

  async function validateInput(inValue: any, pointDef: PointDefType, col: any) {
    let pv: PointValue = {
      PointId: pointDef.PointId,
      ObjectId: col.ObjectId,
      ColumnCode: col.ColumnCode,
      Measure: inValue,
      FailedFlag: false,
    };
    let errors = validationErrors.filter((e) => e.column !== col.ObjectId);
    if (!!pointDef && pointDef.RequiredFlag && !inValue) {
      pv.FailedFlag = true;
      errors.push({
        id: pointDef.PointId,
        column: col.ObjectId,
        error: pointDef.Description + "->" + translations["lbl_value_required"] || "Value is Required.",
      });
    } else if (
      (col.ImpliesFailureFlag || pointDef.Limit) &&
      !!pointDef &&
      !!inValue &&
      !!pointDef.Meta &&
      pointDef.ValueDataType === "n" &&
      !isNaN(+inValue)
    ) {
      //high/low
      if (!!pointDef.Precision) {
        pv.Measure = Number.parseFloat(inValue).toFixed(pointDef.Precision);
      }
      if (+inValue > pointDef.HighValue || +inValue < pointDef.LowValue) {
        pv.FailedFlag = true;
        errors.push({
          id: pointDef.PointId,
          column: col.ObjectId,
          error: pointDef.Description + "->" + getMetaString(pointDef.Meta),
        });
      }
    }
    //precision
    if (
      !col.ImpliesFailureFlag &&
      !!pointDef &&
      !!inValue &&
      pointDef.ValueDataType === "n" &&
      !isNaN(+inValue)
    ) {
      if (pointDef.Precision === 0) {
        pv.Measure = Math.round(Number.parseFloat(inValue)).toString();
      } else {
        pv.Measure = Number.parseFloat(inValue).toFixed(pointDef.Precision);
      }
    }
    col.Failed = pv.FailedFlag;
    setPointVals([...pointVals.filter((p) => p.ObjectId !== col.ObjectId), pv]);
    setValidationErrors(errors);
  }

  async function validateList(inValue: any, pointDef: PointDefType, col: any) {
    let pv: PointValue = {
      PointId: pointDef.PointId,
      ObjectId: col.ObjectId,
      ColumnCode: col.ColumnCode,
      Measure: inValue,
      FailedFlag: false,
    };
    let errors = validationErrors.filter((e) => e.column !== col.ObjectId);
    if (!!pointDef && pointDef.RequiredFlag && !inValue) {
      errors.push({
        id: pointDef.PointId,
        column: col.ObjectId,
        error:
          pointDef.Description + "->" + translations["lbl_value_required"] || "Value is Required.",
      });
    } else if (
      col.ImpliesFailureFlag &&
      !!pointDef &&
      !!inValue &&
      !!pointDef.Meta
    ) {
      let metaVal = pointDef.Meta.find((m) => m.MetaType === "valid_value" && m.MetaSubtype === inValue);
      if (metaVal?.MetaValue === 1) {
        pv.FailedFlag = true;
        errors.push({
          id: pointDef.PointId,
          column: col.ObjectId,
          error: pointDef.Description + "->" + metaVal.MetaSubtype,
        });
      }
    }
    col.Failed = pv.FailedFlag;
    setPointVals([...pointVals.filter((p) => p.ObjectId !== col.ObjectId), pv]);
    setValidationErrors(errors);
  }

  async function setCheckBoxValue(
    inValue: boolean,
    pointDef: PointDefType,
    col: any
  ) {
    let pv: PointValue = {
      PointId: pointDef.PointId,
      ObjectId: col.ObjectId,
      ColumnCode: col.ColumnCode,
      Measure: String(inValue),
      FailedFlag: false,
    };
    setPointVals([...pointVals.filter((p) => p.ObjectId !== col.ObjectId), pv]);
  }

  async function completeInspectionResults() {
    setShowLoading(true);
    const comments = (
      document.getElementById("txtComments") as HTMLInputElement
    ).value;
    const response = await saveInspection(
      displayWo,
      inspectionId,
      pointVals,
      inspectionDefinition!,
      checkOverride,
      comments,
      currentTestEquip
    );
    if (response.isError) {
      processError(response.status, response.data);
    } else {
      // Remove inspection from local storage if present
      await removeInspectionFromLocal(inspectionId, displayWo);
      //process respose data?
      presentAlert({
        message:
          translatedMessages["CompInsp"]?.MessageText ||
          "Inspection completed successfully",
        buttons: [
          {
            text: translations["lbl_btn_ok"] || "OK",
            handler: () => { history.goBack(); },
          },
        ],
      });
      setErrorState("");
    }
    setShowLoading(false);
  }

  async function saveInspectionResultsToLocal() {
    setShowLoading(true);
    const comments = (
      document.getElementById("txtComments") as HTMLInputElement
    ).value;
    const res = await saveInspectionToLocal(
      displayWo,
      equipment,
      inspectionId,
      pointVals,
      inspectionDefinition!,
      checkOverride,
      comments,
      currentTestEquip
    );
    setShowLoading(false);
    if (res) {
      presentAlert({
        header: "Success",
        message: "Inspection successfully saved!",
        buttons: [translations["lbl_btn_ok"] || "OK"],
      });
    } else {
      presentAlert({
        header: "Error",
        message: "Inspection was not saved",
        buttons: [translations["lbl_btn_ok"] || "OK"],
      });
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              icon={{ ios: chevronBackOutline, md: chevronBackSharp }}
              text=""
            />
          </IonButtons>
          <IonTitle>
            {translations["lbl_category_inspection"] || "Inspections"}
          </IonTitle>
        </IonToolbar>
        <OnlineStatus />
        <ApiError errorData={errorState} />
      </IonHeader>

      <IonContent fullscreen>
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading && !inRefresh}
          onDidDismiss={() => setShowLoading(false)}
        />
        {
          <IonCard>
            <IonCardHeader>
              <IonCardTitle>{displayWo}</IonCardTitle>
              <IonCardSubtitle>{`${inspectionDefinition?.InspectionType ?? ""
                } - ${inspectionDefinition?.Description ?? ""} -${inspectionDefinition?.InspectionId ?? ""
                }`}</IonCardSubtitle>
              <IonCardSubtitle>{`${translations["lbl_category_equipment"] || "Equipment"
                }: ${equipment}`}</IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
              <IonAccordionGroup>
                <IonAccordion
                  disabled={inspectionDefinition?.Instructions == null}
                  value="instructions"
                >
                  <IonItem slot="header">
                    <IonLabel>
                      {translations["lbl_category_instructions"] ||
                        "Instructions"}
                    </IonLabel>
                  </IonItem>
                  <div className="ion-padding" slot="content">
                    <ReactQuill
                      readOnly
                      value={inspectionDefinition?.Instructions}
                      modules={{ toolbar: [[]] }}
                    />
                  </div>
                </IonAccordion>
                <IonAccordion
                  disabled={
                    inspectionDefinition?.MaterialRequirements == null ||
                    inspectionDefinition?.MaterialRequirements === "NULL"
                  }
                  value="material"
                >
                  <IonItem slot="header">
                    <IonLabel>
                      {translations["lbl_material"] || "Material"}
                    </IonLabel>
                  </IonItem>
                  <div className="ion-padding" slot="content">
                    <ReactQuill
                      readOnly
                      value={inspectionDefinition?.MaterialRequirements}
                      modules={{ toolbar: [[]] }}
                    />
                  </div>
                </IonAccordion>
              </IonAccordionGroup>
            </IonCardContent>
          </IonCard>
        }
        {!!inspectionDefinition?.Elements && (
          <IonCard>
            {inspectionDefinition.Elements.map(function (element: any) {
              return (
                <IonItem key={`${element.FieldLabel}`}>
                  <IonTextarea
                    autoGrow
                    readonly
                    value={`${element.FieldLabel}: ${element.FieldValue}`}
                  ></IonTextarea>
                </IonItem>
              );
            })}
          </IonCard>
        )}
        {
          inspectionDefinition?.InspectionType === "Calibration" && (
            <IonCard>
              <IonList>
                <IonItem>
                  <IonLabel color={!!testEquipRule ? requiredColor : undefined} position="floating">Testing Equipment</IonLabel>
                  <IonSelect
                    interface="popover"
                    disabled={disableInsp}
                    placeholder="Select testing equipment(s)"
                    multiple={true}
                    selectedText={currentTestEquip === "[]" ? "" : JSON.parse(currentTestEquip).reduce((a: TestEquipment, c: TestEquipment) => a + c.Equipment + " ", "")}
                    onIonChange={(ev) => { setCurrentTestEquip(JSON.stringify(ev.detail.value)) }}
                  >
                    {testEquipmentList.map((equip) => (
                      <IonSelectOption key={equip.Equipment} value={equip}>{equip.Equipment + " (Exp:" + new Date(equip.CalibrationExpiresDate).toLocaleDateString() + ")"}</IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
              </IonList>
            </IonCard>
          )
        }
        <IonCard>
          <IonCardContent class="inspection-ioncard-content">
            {pointEquipment.length === 1 && pointEquipment[0].Equipment === 'No Equipment' ? (<IonGrid>
              {pointDefs?.sort((a, b) => { return a.RowOrder - b.RowOrder; }).map(function (p, i) {
                return (
                  <>
                    <IonRow key={i}>
                      <IonCol
                        class="inspection-gridcol-header"
                        key={`${i}.1`}
                      >
                        {p.Label}
                      </IonCol>
                      {p.Columns?.sort((a, b) => {
                        return a.ColumnOrder - b.ColumnOrder;
                      }).map(function (col) {
                        return (
                          <IonCol
                            class="inspection-gridcol-header-input"
                            key={col.ObjectId}
                          >
                            {col.ColumnCode === "_Result" ? "-" : col.ColumnCode}
                          </IonCol>
                        );
                      })}
                    </IonRow>
                    <IonRow key={`${i}.2`}>
                      <IonCol key={`${i}.2.1`}>
                        <IonLabel
                          color={p.RequiredFlag ? requiredColor : undefined}
                        >
                          {p.Description}
                        </IonLabel>
                      </IonCol>
                      {p.Columns?.sort((a, b) => {
                        return a.ColumnOrder - b.ColumnOrder;
                      }).map(function (col, j) {
                        return (
                          <IonCol key={`${col.ObjectId}.${j}`}>
                            {p.ValueDataType === "b" ? (
                              <IonItem
                                color={p.RequiredFlag ? requiredColor : undefined}
                              >
                                <IonCheckbox
                                  slot="end"
                                  name={col.ObjectId}
                                  disabled={disableInsp}
                                  checked={pointVals.find((p) => p.ObjectId === col.ObjectId)?.Measure === "true"}
                                  onIonChange={(ev) => setCheckBoxValue(ev.target.checked, p, col)}
                                ></IonCheckbox>
                              </IonItem>
                            ) : p.ValueDataType === "l" ? (
                              <IonItem>
                                <IonSelect
                                  name={col.ObjectId}
                                  class={col.Failed ? "inspection-ion-select-fail" : "inspection-ion-select"}
                                  interface="popover"
                                  disabled={disableInsp}
                                  placeholder={translations["lbl_select list"] || "select from list"}
                                  value={pointVals.find((p) => p.ObjectId === col.ObjectId)?.Measure}
                                  onIonChange={(ev) => validateList(ev.detail.value, p, col)}
                                >
                                  {!!p.Meta && p.Meta.map(function (mv, index) {
                                    return (
                                      <IonSelectOption key={index}>
                                        {mv.MetaSubtype}
                                      </IonSelectOption>
                                    );
                                  })}
                                </IonSelect>
                              </IonItem>
                            ) : (
                              <IonItem>
                                <IonInput
                                  class={col.Failed ? "inspection-gridcol-inputcol-fail" : "inspection-gridcol-inputcol"}
                                  name={col.ObjectId}
                                  disabled={disableInsp}
                                  required={p.RequiredFlag}
                                  type={p.ValueDataType === "n" ? "number" : "text"}
                                  inputMode={p.ValueDataType === "n" ? "decimal" : "text"}
                                  placeholder={p.DefaultValue}
                                  min= {p.ValueDataType === "n" ? 0 : undefined}
                                  max={p.ValueDataType === "n" ? p.HighValue : undefined}
                                  value={pointVals.find((p) => p.ObjectId === col.ObjectId)?.Measure}
                                  onIonBlur={(ev) => validateInput(ev.target.value, p, col)}
                                ></IonInput>
                                {p.ValueDataType !== "l" && !!p.Meta && getMetaNotes(p.Meta)}
                              </IonItem>
                            )}
                          </IonCol>
                        );
                      })}
                    </IonRow>
                  </>
                );
              })}
            </IonGrid>) : (

              <IonAccordionGroup>
                {pointEquipment.map((PointEqDescription, index) => (
                  <IonAccordion key={index} value={PointEqDescription.Equipment}>
                    <IonItem slot="header">
                      <IonLabel>
                        {PointEqDescription.Equipment === "No Equipment" ? translations["lbl_no_equipment"] || "No Equipment" : PointEqDescription.Equipment + ' - ' + PointEqDescription.Description}
                      </IonLabel>
                    </IonItem>
                    <div className="ion-padding" slot="content">
                      <IonGrid>
                        {pointDefs?.filter(p => p.Equipment === PointEqDescription.Equipment).sort((a, b) => { return a.RowOrder - b.RowOrder; }).map(function (p, i) {
                          return (
                            <>
                              <IonRow key={i}>
                                <IonCol
                                  class="inspection-gridcol-header"
                                  key={`${i}.1`}
                                >
                                  {p.Label}
                                </IonCol>
                                {p.Columns?.sort((a, b) => {
                                  return a.ColumnOrder - b.ColumnOrder;
                                }).map(function (col) {
                                  return (
                                    <IonCol
                                      class="inspection-gridcol-header-input"
                                      key={col.ObjectId}
                                    >
                                      {col.ColumnCode === "_Result" ? "-" : col.ColumnCode}
                                    </IonCol>
                                  );
                                })}
                              </IonRow>
                              <IonRow key={`${i}.2`}>
                                <IonCol key={`${i}.2.1`}>
                                  <IonLabel
                                    color={p.RequiredFlag ? requiredColor : undefined}
                                  >
                                    {p.Description}
                                  </IonLabel>
                                </IonCol>
                                {p.Columns?.sort((a, b) => {
                                  return a.ColumnOrder - b.ColumnOrder;
                                }).map(function (col, j) {
                                  return (
                                    <IonCol key={`${col.ObjectId}.${j}`}>
                                      {p.ValueDataType === "b" ? (
                                        <IonItem
                                          color={p.RequiredFlag ? requiredColor : undefined}
                                        >
                                          <IonCheckbox
                                            slot="end"
                                            name={col.ObjectId}
                                            disabled={disableInsp}
                                            checked={pointVals.find((p) => p.ObjectId === col.ObjectId)?.Measure === "true"}
                                            onIonChange={(ev) => setCheckBoxValue(ev.target.checked, p, col)}
                                          ></IonCheckbox>
                                        </IonItem>
                                      ) : p.ValueDataType === "l" ? (
                                        <IonItem>
                                          <IonSelect
                                            name={col.ObjectId}
                                            class={col.Failed ? "inspection-ion-select-fail" : "inspection-ion-select"}
                                            interface="popover"
                                            disabled={disableInsp}
                                            placeholder={translations["lbl_select list"] || "select from list"}
                                            value={pointVals.find((p) => p.ObjectId === col.ObjectId)?.Measure}
                                            onIonChange={(ev) => validateList(ev.detail.value, p, col)}
                                          >
                                            {!!p.Meta && p.Meta.map(function (mv, index) {
                                              return (
                                                <IonSelectOption key={index}>
                                                  {mv.MetaSubtype}
                                                </IonSelectOption>
                                              );
                                            })}
                                          </IonSelect>
                                        </IonItem>
                                      ) : (
                                        <IonItem>
                                          <IonInput
                                            class={col.Failed ? "inspection-gridcol-inputcol-fail" : "inspection-gridcol-inputcol"}
                                            name={col.ObjectId}
                                            disabled={disableInsp}
                                            required={p.RequiredFlag}
                                            type={p.ValueDataType === "n" ? "number" : "text"}
                                            inputMode={p.ValueDataType === "n" ? "decimal" : "text"}
                                            placeholder={p.DefaultValue}
                                            min= {p.ValueDataType === "n" ? 0 : undefined}
                                            max={p.ValueDataType === "n" ? p.HighValue : undefined}
                                            value={pointVals.find((p) => p.ObjectId === col.ObjectId)?.Measure}
                                            onIonBlur={(ev) => validateInput(ev.target.value, p, col)}
                                          ></IonInput>
                                          {p.ValueDataType !== "l" && !!p.Meta && getMetaNotes(p.Meta)}
                                        </IonItem>
                                      )}
                                    </IonCol>
                                  );
                                })}
                              </IonRow>
                            </>
                          );
                        })}
                      </IonGrid>
                    </div>

                  </IonAccordion>
                ))}
              </IonAccordionGroup>
            )}
          </IonCardContent>
        </IonCard>
        <IonCard>
          <IonCardContent class="inspection-ioncard-content">
            <IonItem fill="outline" hidden={true} color="danger">
              <IonTextarea
                class="inspection-check-label"
                color="danger"
                readonly
                autoGrow
                value={validationErrors.map((p) => p.error).join("\r\n")}
              ></IonTextarea>
            </IonItem>
            <IonItem
              disabled={disableInsp}
              fill="outline"
            >
              <IonTextarea
                class="inspection-check-label"
                readonly
                autoGrow
                value={
                  translatedMessages["ICFailureFlagMessage"]?.MessageText ||
                  "This Inspection could not be completed within specifications."
                }
              ></IonTextarea>
              <IonCheckbox
                slot="start"
                onIonChange={(ev) => setCheckOverride(ev.target.checked)}
              ></IonCheckbox>
            </IonItem>
            <IonItem disabled={disableInsp} fill="outline">
              <IonLabel position="floating">
                {translations["lbl_category_comments"] || "Comments"}
              </IonLabel>
              <IonTextarea
                id="txtComments"
                placeholder={
                  translations["lbl_enter comments"] || "Enter Comments"
                }
                autoGrow={true}
                value={comments}
                onIonChange={(ev) => setComments(String(ev.detail.value))}
              ></IonTextarea>
            </IonItem>
          </IonCardContent>
        </IonCard>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              color="primary"
              fill="solid"
              disabled={
                disableInsp ||
                (pointVals.find((p) => p.FailedFlag) != null && !checkOverride)
                || (!!testEquipRule && currentTestEquip === "[]")
              }
              onClick={() => completeInspectionResults()}
            >
              {translations["lbl_btn_complete"] || "Complete"}
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton
              color="primary"
              fill="solid"
              disabled={disableInsp}
              onClick={saveInspectionResultsToLocal}
            >
              {translations["lbl_btn_save"] || "Save"}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default Inspection;
